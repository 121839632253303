<template>
  <div>
    <v-snackbar
      class="mb-10"
      v-model="snackbar"
      bottom
      right
      color="red"
      rounded="pill"
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      class="mb-10"
      v-model="snackbarSuccess"
      bottom
      right
      color="green"
      rounded="pill"
    >
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbarSuccess = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import EventBus, { ACTIONS } from "../../eventbus/index.js";
export default {
  name: "ApiErrorMessage",
  data() {
    return {
      snackbar: false,
      snackbarSuccess: false,
      snackbarMessage: "",
    };
  },
  mounted() {
    EventBus.$on(ACTIONS.SNACKBAR, (message) => {
      if (message.success == true) {
        this.snackbarMessage = message.message;
        this.snackbarSuccess = true;
      } else {
        this.snackbarMessage = message;
        this.snackbar = true;
      }
    });
  },
};
</script>
